import React, {useRef, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';


export default function Task() {

  const dataGridRef = useRef(null);

  const { user } = useAuth();

  const [Data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.post(backendlink + '/admin/allpatient', {}, {
          headers: {
            token: user.token
          }
        });
        const formattedData = result.data.result.map(item => ({
          ...item,
          createdAt: new Date(item.createdAt).toLocaleString()  // Convert the date to a human-readable format
        }));
        const substitutedData = formattedData.map(item => {
          let lessonString;
          switch(item.progress) {
            case 2:
              lessonString = "Lesson 1";
              break;
            case 3:
              lessonString = "Lesson 2";
              break;
            case 4:
              lessonString = "Lesson 3";
              break;
            case 5:
              lessonString = "Lesson 4";
              break;
            case 6:
              lessonString = "Lesson 5";
              break;
            case 7:
              lessonString = "Lesson 6";
              break;
            case 8:
              lessonString = "Questionnaire T1";
              break;
            case 9:
              lessonString = "Questionnaire T2";
              break;
            case 10:
              lessonString = "Questionnaire T3";
              break;
            // Add more cases as needed
            default:
              lessonString = item.progress; // If no match, keep the original value
          }
        
          return {
            ...item,
            progress: lessonString
          };
        });
        setData(substitutedData);
        // setData(result.data.result);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);


  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
    });
}

  function handleRowDoubleClick(e) {
    const patientId = e.data.patientID;
    navigate('/profile', { state: { patientId: patientId } });

  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Patients Profile</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={Data}
        keyExpr={'patientID'}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting={onExporting}
        onRowDblClick={handleRowDoubleClick}

      >
        <Export enabled={true} />
        <Paging defaultPageSize={100} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />

        <Column
          dataField={'patientID'}
          caption={'ID'}
        />
        <Column
          dataField={'group'}
          caption={'Group'}
        />
        <Column
          dataField={'progress'}
          caption={'Progress'}
          alignment='left'
        />
        <Column
          dataField={'studySite'}
          caption={'Study Site'}
        />
        {/* <Column
          dataField={'questionnaireScore'}
          caption={'Questionnaire Score'}
        >
        </Column>
        <Column
          dataField={'createdAt'}
          caption={'Account Created on'}
        >
        </Column> */}

      </DataGrid>

      
    </React.Fragment>
)}

