import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { backendlink } from '../config';
import axios from 'axios';
import Cookies from 'js-cookie';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
}, []);

  const signIn = useCallback(async (email, password) => {
    try {
      const response = await axios.post(backendlink + '/admin/signin', {
        email: email,
        password: password
      });
      if (response.data.error == 0) {
        setUser(response.data.data);
        Cookies.set("user", JSON.stringify(response.data.data), {expires: 7});
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response)
      return error.response.data;
    }
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    Cookies.remove("user");
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, setUser, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
