import React, {useRef, useEffect, useState, useCallback} from 'react';
import { backendlink } from '../../config';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
  import { CheckBox, CheckBoxTypes } from 'devextreme-react/check-box';
  import TextArea, { TextAreaTypes } from 'devextreme-react/text-area';



  const rowPrepared = (e) => {

    if (e.rowType === "data") {
        if (e.data.isFollowUp == 'Yes') {
          e.rowElement.style.backgroundColor = "#878787";
          e.rowElement.style.color = "white";
            // e.cellElement.style.cssText = "color: white; background-color: red";
            // // or
            // e.rowElement.classList.add("my-class");
            // // To override alternation color
            // e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
        }
    }
}

export default function HelpRequests() {
    
    const { user } = useAuth();

    const [Data, setData] = useState(null);

    const [popupvisible, setpopupvisible] = useState(false)

    const [popupinfo, setpopupinfo] = useState(null);

    async function fetchData() {
      try {
        const result = await axios.post(backendlink + '/admin/gethelprequests', {}, {
          headers: {
            token: user.token
          }
        });
        console.log(result)
        // Transform the data structure
        const transformedData = result.data.result.map(item => ({
            id: item.id,
            text: item.text,
            createdAt: new Date(item.createdAt).toLocaleString() ,
            phoneNumber: item.user ? item.user.phoneNumber : null,
            group: item.user ? item.user.group : null,
            patientID: item.user ? item.user.patientID : null, // Extract patientID if user object exists
            isFollowUp: item.isFollowUp == false ? "No" : "Yes",
            messageRemark: item.messageRemark
        }));
        
        setData(transformedData);
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
        fetchData();
      }, []);

      const emailButtonOptions = {
        icon: 'save',
        text: 'Update',
        onClick: handleSendApiRequest
      };
      const closeButtonOptions = {
        text: 'Close',
        onClick: handleClosePopup
      };
    
      async function handleSendApiRequest() {
        console.log("65", popupinfo)
        try{
          const result = await axios.post(backendlink + '/admin/edithelprequest',{
            userToken: user.token,
            popupinfo: popupinfo
          },{
          headers: {
              token: user.token
          }
          })
          .then((res)=>{
            handleClosePopup();
            fetchData();
          });
        }catch(error){
            console.log("error")
            alert("There was an error editing, please try again.")
        }
      }
    
      function handleClosePopup() {
        setpopupvisible(false);
        setpopupinfo(false)
        
      }

      function handleOpenPopup(e) {
        setpopupvisible(true);
        console.log(e.data)
        setpopupinfo(e.data)
        
      }

      const onCheckboxValueChange = useCallback((e) => {
        console.log("eeee", e)
        setpopupinfo(prevState => ({
          ...prevState,
          isFollowUp: e
        }));
      }, []);

      const onTextAreaValueChange = useCallback((e) => {
        setpopupinfo(prevState => ({
          ...prevState,
          messageRemark: e
        }));
      }, []);

    return(
        <React.Fragment>
        <h2 className={'content-block'}>Help Requests</h2>
  
        <DataGrid
          className={'dx-card wide-card'}
          dataSource={Data}
          keyExpr={'patientID'}
          showBorders={false}
          // focusedRowEnabled={true}
          // defaultFocusedRowIndex={0}
          allowColumnResizing={true}
          columnAutoWidth={true}
          // columnHidingEnabled={true}
          onRowPrepared={rowPrepared}
          onRowClick={handleOpenPopup}
  
        >
          <Paging defaultPageSize={100} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
  
          <Column
            dataField={'patientID'}
            caption={'Patient ID'}
            fixed={true}
          />
          <Column
            dataField={'text'}
            caption={'Help Request'}
          />
            <Column
            dataField={'phoneNumber'}
            caption={'Phone Number'}
          />
          <Column
            dataField={'group'}
            caption={'Group'}
          />
          <Column
            dataField={'isFollowUp'}
            caption={'is Follow Up'}
          />
          <Column
            dataField={'messageRemark'}
            caption={'Remark'}
          />
          <Column
            dataField={'createdAt'}
            caption={'Request Created on'}
          >
          </Column>
  
        </DataGrid>
        <Popup
          visible={popupvisible}
          dragEnabled={true}
          // hideOnOutsideClick={true}
          // content={popupContent}
          width={500}
          height={380}
          title='Update Help Request'
        >
      <div>
        {popupinfo && <h6>You are updating user {popupinfo.patientID}'s request</h6>}
        {popupinfo && <CheckBox text='Is Follow Up' value={popupinfo.isFollowUp == 'Yes'} onValueChange={onCheckboxValueChange}></CheckBox>}
        {popupinfo && <TextArea value={popupinfo.messageRemark} onValueChange={onTextAreaValueChange}></TextArea>}
      </div>
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={emailButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />

        </Popup>
        
      </React.Fragment>
    )
}
