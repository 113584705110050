import React, {useState, useEffect} from 'react';
import './home.scss';
import {
  Chart, Series, CommonSeriesSettings, Legend, Export, Tooltip, Title, ValueAxis, Grid, Margin, ArgumentAxis
} from 'devextreme-react/chart';

import PieChart, {
  Legend as PieLegend,
  Series as PieSeries,
  Tooltip as PieTooltip,
  Format,
  Label,
  Connector,
  Export as PieExport,
} from 'devextreme-react/pie-chart';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';

import axios from 'axios';
import { backendlink } from '../../config';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react';

export default function Home() {

  const { user } = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.post(backendlink + '/admin/getdashboarddata', {}, {
          headers: {
            token: user.token
          }
        });
        setData1(result.data.data1);
        setData2(result.data.data2);
        setData4(result.data.data4);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function DownloadPDF (){
    try{
      const response = await axios.get(backendlink + '/download-pdf', {
        headers: {
          token: user.token
        },
        responseType: 'arraybuffer' // set the response type to arraybuffer
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Doctor Version Flowchart.pdf';
      link.click();
  
    } catch (error) {
      console.log(error);
    }
  }

  async function Download (){
    try {
      // const response = await axios.post(backendlink + '/admin/downloaddata', {

      // }, {
      //   headers: {
      //     token: user.token
      //   }
      // });
      // console.log("hi", response) 
      const response = await axios.post(backendlink + '/admin/downloaddata', {
      }, {
        headers: {
          token: user.token
        },
        responseType: 'blob' // set the response type to blob
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xlsx');
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      console.log(error);
    }
  };

  const [Data1, setData1] = useState([
  //   {
  //   time: 'Last Month',
  //   eligible: 10,
  //   ineligible: 62,
  // }, {
  //   time: 'All time',
  //   eligible: 32,
  //   ineligible: 149,
  // }
]);

  const [Data2, setData2] = useState([
  // {
  //   progress: 'Questionnaire T0',
  //   val: 10,
  // }, {
  //   progress: 'Lesson 1',
  //   val: 14,
  // },{
  //   progress: 'Lesson 2',
  //   val: 4,
  // }, {
  //   progress: 'Lesson 3',
  //   val: 7,
  // },{
  //   progress: 'Lesson 4',
  //   val: 14,
  // }, {
  //   progress: 'Lesson 5',
  //   val: 10,
  // },{
  //   progress: 'Lesson 6',
  //   val: 4,
  // }, {
  //   progress: 'Questionnaire T1',
  //   val: 8,
  // }
]);

  const groups = [
    { value: 'A', name: 'Group A' },
    { value: 'B', name: 'Group B' },
  ];

  // const [Data3, setData3]= useState([{
  //   progress: 'Questionnaire T0',
  //   A: 3.5,
  //   B: 4.5
  // },
  // {
  //   progress: 'Lesson 1',
  //   A: 6.2,
  //   B: 0.8
  // }, {
  //   progress: 'Lesson 2',
  //   A: 4.5,
  //   B: 4.3
  // }, {
  //   progress: 'Lesson 3',
  //   A: 5.7,
  //   B: 1.8
  // }, {
  //   progress: 'Lesson 4',
  //   A: 3.1,
  //   B: 2.6
  // }, {
  //   progress: 'Lesson 5',
  //   A: 6.8,
  //   B: 5.4
  // }, {
  //   progress: 'Lesson 6',
  //   A: 0.9,
  //   B: 2.7
  // }, {
  //   progress: 'Questionnaire T1',
  //   A: 3.7,
  //   B: 2.9
  // }]);

  const [Data4, setData4] = useState([  
    {
      score: 'Ongoing',
      val: 10,
    }, {
      score: '0 - 16',
      val: 14,
    },{
      score: '17-21',
      val: 4,
    }, {
      score: '22-36',
      val: 7,
    },
  ]);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>
      <ResponsiveBox>
      <Row ratio={1}></Row>
      <Row ratio={1}></Row>
      <Col ratio={1}></Col>
      <Col ratio={1}></Col>
      {/* <Col ratio={1}></Col> */}
      <Item>
        <Location row={0} col={0} colspan={1}></Location>
        <Chart
        id="chart"
        dataSource={Data1}
      >
        <Title
          text="Number of eligible patients over time" horizontalAlignment='center'
        />
        <CommonSeriesSettings argumentField="time" type="stackedbar" />
        <Series valueField="eligible" name="Eligible" />
        <Series valueField="ineligible" name="Ineligible" />

        <Legend verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="right"
          title={"Creation Date"}
        />
          <ValueAxis
            name="total"
            position="left"
            title="Number of users  "
          >
            <Grid visible={true} />
          </ValueAxis>
        <Export enabled={true} />
        <Tooltip
          enabled={true}
          // customizeTooltip={customizeTooltip}
        />
      </Chart>
      </Item>
      <Item>
        <Location row={0} col={1} colspan={1}></Location>
        <PieChart
        id="pie"
        type="doughnut"
        title="Number of patients in each lesson"
        palette="Soft Pastel"
        dataSource={Data2}
      >
        <PieSeries argumentField="progress">
          <Label visible={true}>
            <Connector visible={true} />
          </Label>
        </PieSeries>
        <PieExport enabled={true} />
        <PieLegend
          margin={0}
          horizontalAlignment="right"
          verticalAlignment="top"
        />
      </PieChart>
        </Item>
        {/* <Item>
      <Location row={0} col={2} colspan={1}></Location>
      <PieChart
        id="pie"
        type="doughnut"
        title="Number of patients in each score range"
        palette="Soft Pastel"
        dataSource={Data4}
      >
        <PieSeries argumentField="score">
          <Label visible={true}>
            <Connector visible={true} />
          </Label>
        </PieSeries>
        <PieExport enabled={true} />
        <PieLegend
          margin={0}
          horizontalAlignment="right"
          verticalAlignment="top"
        />
      </PieChart>
      </Item> */}
      {/* <Item>
        <Location row={1} col={0} colspan={3}></Location>
        <Chart
          palette="Violet"
          dataSource={Data3}
        >
          <CommonSeriesSettings
            argumentField="progress"
            type="line"
          />
          {/* <Series
              key="progress"
              valueField="progress"
              name="timetaken" /> 
              {
                groups.map((item) => <Series
                  key={item.value}
                  valueField={item.value}
                  name={item.name} />)
              }
          <Margin bottom={20} top={40}/>
          <ArgumentAxis
            valueMarginsEnabled={false}
            discreteAxisDivisionMode="crossLabels"
            title={"Progress"}
          >
            <Grid visible={true} />
          </ArgumentAxis>
          
          <ValueAxis
            name="total"
            position="left"
            title="Average completion time (days)"
          >
            <Grid visible={true} />
          </ValueAxis>

          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
          />
          <Export enabled={true} />
          <Title text="Average Time Taken for each section" horizontalAlignment="center"></Title>
          <Tooltip enabled={true} />

        </Chart>
      </Item> */}
      </ResponsiveBox>
      <Button style={{ backgroundColor: '#ff5722', color:'#FFFFFF', marginTop: "20px"}} onClick={Download} text='Download all data'></Button>

      </h2>
      {/* <Button style={{ backgroundColor: '#ff5722', color:'#FFFFFF', marginTop: "20px"}} onClick={DownloadPDF} text='Download PDF'></Button> */}
    </React.Fragment>
)}
