export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Progress Report',
    path: '/progressreport',
    icon: 'floppy'
  },
  // {
  //   text: 'Profile',
  //   path: '/profile',
  //   icon: 'floppy'
  // },
  {
    text: 'Patients Profile',
    path: '/patientlist',
    icon: 'floppy'
  },
  ];
