export const GenderOptions = {items: ['M', 'F']};

export const CaseStatusOptions = {items: ['Active', 'Withdrew', 'Completed', 'Incomplete']}

export const ConsentStatusOptions = {items: ['Completed', 'Incomplete', 'Oral Consent']}

export const ScreeningStatusOptions = {items: ['Completed', 'Incomplete', 'Default', 'Refused', 'Withdrew', 'Pending', 'Deceased', 'Excluded']}

export const BaselineT0StatusOptions = {items: ['Completed', 'Incomplete', 'Default', 'Refused', 'Withdrew', 'Pending', 'Deceased', 'Excluded']}

export const T1FollowupStatusOptions = {items: ['Completed', 'Incomplete', 'Default', 'Refused', 'Withdrew', 'Pending', 'Deceased', 'Excluded']}

export const T2FollowupStatusOptions = {items: ['Completed', 'Incomplete', 'Default', 'Refused', 'Withdrew', 'Pending', 'Deceased', 'Excluded']}

export const T3FollowupStatusOptions = {items: ['Completed', 'Incomplete', 'Default', 'Refused', 'Withdrew', 'Pending', 'Deceased', 'Excluded']}

export const InterventionStatusOptions = {items: ['Active', 'Withdrew', 'Completed', 'Incomplete']}
