
import React, { useState, useEffect, useRef } from 'react';
import './profile.scss';
import Form, {
  Item,
  Label
} from 'devextreme-react/form';
import DateBox from 'devextreme-react/date-box';
import { Button, CheckBox, TextBox } from 'devextreme-react';
import { useLocation } from 'react-router-dom';
import { FileUploader } from 'devextreme-react';
import { Toast } from 'devextreme-react/toast';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';


import { GenderOptions, CaseStatusOptions, ConsentStatusOptions, ScreeningStatusOptions, BaselineT0StatusOptions, T1FollowupStatusOptions, T2FollowupStatusOptions, T3FollowupStatusOptions, InterventionStatusOptions } from './profileOptions.js'
// import { Item } from 'devextreme-react/data-grid';
import { backendlink } from '../../config';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';

export default function Profile() {
  const [notes, setNotes] = useState(
    'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.'
  );
  const [patientId, setPatientId] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setpatient] = useState([])

  const handleSearchButtonClick = () => {
    // setPatientInformationFormState(<PatientInformationForm patientID={patientId.value} patientPhone={patientPhone.value} setIsLoading={setIsLoading}/>)
  };

  const location = useLocation();

  const navigate = useNavigate();

  const { user } = useAuth();

  const [file, setfile] = useState(null);

  const [popupvisible, setpopupvisible] = useState(false)

  const popupappear = () => {
    setpopupvisible(true);
  }

  const deleteuserButtonOptions = {
    text: "Yes",
    onClick: handleSendApiRequest
  };
  const closeButtonOptions = {
    text: "No",
    onClick: handleClosePopup
  };

  async function handleSendApiRequest() {
    try {


      const response = await axios.post(backendlink + '/admin/deletepatient', {
        usertoken: patient.userToken
      }, {
        headers: {
          token: user.token
        }
      }).then(response => {
        if (response.data.errCode == 0) {
          notify("Patient has been deleted", "success", 2000);
          navigate('/patientlist');
        }
      })

    } catch (error) {
      console.log("error")
    }
  }

  function handleClosePopup() {
    setpopupvisible(false);

  }

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'success',
    message: 'Profile has been updated',
  });

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  useEffect(() => {
    setPatientId(location.state.patientId); // get id
    async function fetchData() {
      try {
        const response = await axios.post(backendlink + '/admin/getpatientinfo', {
          patientID: location.state.patientId
        }, {
          headers: {
            token: user.token
          }
        });
        console.log(response.data.result)
        setpatient(response.data.result)

      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

  }, []);


  async function onSubmit() {
    // e.preventDefault();
    console.log("uploaded patinet", patient)
    try {
      const response = await axios.post(backendlink + '/admin/savepatientinfo', {
        patient: patient
      }, {
        headers: {
          token: user.token
        }
      });
      if (response.data.error == 0) {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'success',
          message: 'User has been updated',
        });
      } else {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: 'error',
          message: "Error updating user",
        });
      }

    } catch (error) {
      console.log(error);
    }
  };

  const fileInputRef = useRef(null);

  const handleCapture = async (event) => {
    // setfile(event.target.files[0])
    const formData = new FormData();
    formData.append('file', event.target.files[0]); // Append the file to the form data
    formData.append('patientId', location.state.patientId); // Append other data to the form data

    try {
      const response = await axios.post(backendlink + '/file/saveconsent', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type in the headers
          token: user.token
        }
      });
      setfile(null);
    } catch (error) {
      console.error(error);
    }
  };

  async function uploadFile() {
    const formData = new FormData();
    formData.append('file', file); // Append the file to the form data
    formData.append('patientId', location.state.patientId); // Append other data to the form data

    try {
      const response = await axios.post(backendlink + '/file/saveconsent', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type in the headers
          token: user.token
        }
      });
      setfile(null);
    } catch (error) {
      console.error(error);
    }
  }
  async function downloadFile() {
    try {
      //we used get and no patientId
      const response = await axios.post(backendlink + '/file/getconsent', { patientId: location.state.patientId }, {
        headers: {
          token: user.token
        },
        responseType: 'blob', // Specify the response type as a blob
      });
      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition'];
      let fileName = location.state.patientId + 'ConsentForm.pdf'; // Default filename
      // Extract filename from HTTP response headers
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2)
          fileName = fileNameMatch[1];
      }
      link.setAttribute('download', fileName); // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      // Handle error, show an error message, etc.
    }
  }

  return (
    <React.Fragment>

      <h2 className={'content-block'}>Patient ID:{patientId}</h2>
      <Button
        style={{ backgroundColor: '#ff5722', color: '#FFFFFF', marginLeft: "35px", padding: "20px" }}>
        <label>
          <input
            type="file"
            accept=".pdf" // Specify allowed file types
            ref={fileInputRef}
            // onChange={handleCapture}
            onChange={(event) => {
              handleCapture(event);
            }}
          />
          Upload File
        </label>
      </Button>
      {/* <Button text='Upload' onClick={uploadFile} style={{ backgroundColor: '#ff5722', color: '#FFFFFF', marginLeft: "30px" }}>Upload File</Button> */}
      <Button text='Download' onClick={downloadFile} style={{ backgroundColor: '#ff5722', color: '#FFFFFF', marginLeft: "30px" }}>Download File</Button>

      {/* <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div> */}
      <div className={'content-block dx-card responsive-paddings'}>
        <Form id={'form'} formData={patient}>
          <Item itemType={'group'} colCount={2} caption={'Patient Information'}>
            <Item dataField={'patientID'} disabled="true">
              <Label text="ID" />
            </Item>
            <Item dataField={'name'}>
              <Label text="Name" />
            </Item>
            <Item dataField={'gender'} editorType='dxSelectBox' editorOptions={GenderOptions}>
              <Label text="Gender" />
            </Item>
            <Item dataField={'phoneNumber'} disabled="true">
              <Label text="Contact Number" />
            </Item>
            <Item dataField={'group'} disabled="true">
              <Label text="Intervention Group" />
            </Item>
            <Item dataField={'cancerType'}>
              <Label text="Cancer Type" />
            </Item>
            <Item dataField={'studySite'}>
              <Label text="Study Site" />
            </Item>
            <Item dataField={'caseStatus'} editorType='dxSelectBox' editorOptions={CaseStatusOptions}>
              <Label text="Case Status" />
            </Item>

          </Item>
          <Item itemType={'group'} colCount={2} caption={'Consent'}>
            <Item dataField={'consentStatus'} editorType='dxSelectBox' editorOptions={ConsentStatusOptions}>
              <Label text="Consent Status" />
            </Item>
            <Item dataField={'consentCompletionDate'} disabled="true">
              <Label text="Consent Completion Date" />
            </Item>
            {/* <Item dataField={'consentAttachment'}>
            <FileUploader 
                selectButtonText="Select File"
                labelText=""
                accept="*"
                uploadMode="useForm"
            />
          </Item> */}
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Screening'}>
            <Item dataField={'screeningStatus'} editorType='dxSelectBox' editorOptions={ScreeningStatusOptions}>
              <Label text="Screening Status" />
            </Item>
            <Item dataField={'screeningCompletionDate'} disabled="true">
              <Label text="Screening Completion Date" />
            </Item>
            <Item dataField={'questionnaireScore'} disabled="true" />
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Baseline T0'}>
            <Item dataField={'t0Status'} editorType='dxSelectBox' editorOptions={BaselineT0StatusOptions}>
              <Label text="Baseline Status" />
            </Item>
            <Item dataField={'t0ExpectedCompletionDate'} disabled="true">
              <Label text="Baseline Expected Completion Date" />
            </Item>
            <Item dataField={'t0CompletionDate'} disabled="true">
              <Label text="Baseline Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'T1 Follow up'}>
            <Item dataField={'t1Status'} editorType='dxSelectBox' editorOptions={T1FollowupStatusOptions}>
              <Label text="T1 Status" />
            </Item>
            <Item dataField={'t1ExpectedCompletionDate'} disabled="true">
              <Label text="T1 Expected Completion Date" />
            </Item>
            <Item dataField={'t1CompletionDate'} disabled="true">
              <Label text="T1 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'T2 Follow up'}>
            <Item dataField={'t2Status'} editorType='dxSelectBox' editorOptions={T2FollowupStatusOptions}>
              <Label text="T2 Status" />
            </Item>
            <Item dataField={'t2ExpectedCompletionDate'} disabled="true">
              <Label text="T2 Expected Completion Date" />
            </Item>
            <Item dataField={'t2CompletionDate'} disabled="true">
              <Label text="T2 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'T3 Follow up'}>
            <Item dataField={'t3Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="T3 Status" />
            </Item>
            <Item dataField={'t3ExpectedCompletionDate'} disabled="true">
              <Label text="T3 Expected Completion Date" />
            </Item>
            <Item dataField={'t3CompletionDate'} disabled="true">
              <Label text="T3 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={2} caption={'Intervention progress'}>
            <Item dataField={'NoOfSessionsCompleted'} disabled="true" />
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 1'}>
            <Item dataField={'session1Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 1 Status" />
            </Item>
            <Item dataField={'session1ExpectedCompletionDate'} disabled="true">
              <Label text="Session 1 Expected Completion Date" />
            </Item>
            <Item dataField={'session1CompletionDate'} disabled="true">
              <Label text="Session 1 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 2'}>
            <Item dataField={'session2Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 2 Status" />
            </Item>
            <Item dataField={'session2ExpectedCompletionDate'} disabled="true">
              <Label text="Session 2 Expected Completion Date" />
            </Item>
            <Item dataField={'session2CompletionDate'} disabled="true">
              <Label text="Session 2 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 3'}>
            <Item dataField={'session3Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 3 Status" />
            </Item>
            <Item dataField={'session3ExpectedCompletionDate'} disabled="true">
              <Label text="Session 3 Expected Completion Date" />
            </Item>
            <Item dataField={'session3CompletionDate'} disabled="true">
              <Label text="Session 3 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 4'}>
            <Item dataField={'session4Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 4 Status" />
            </Item>
            <Item dataField={'session4ExpectedCompletionDate'} disabled="true">
              <Label text="Session 4 Expected Completion Date" />
            </Item>
            <Item dataField={'session4CompletionDate'} disabled="true">
              <Label text="Session 4 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 5'}>
            <Item dataField={'session5Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 5 Status" />
            </Item>
            <Item dataField={'session5ExpectedCompletionDate'} disabled="true">
              <Label text="Session 5 Expected Completion Date" />
            </Item>
            <Item dataField={'session5CompletionDate'} disabled="true">
              <Label text="Session 5 Completion Date" />
            </Item>
          </Item>
          <Item itemType={'group'} colCount={3} caption={'Session 6'}>
            <Item dataField={'session6Status'} editorType='dxSelectBox' editorOptions={T3FollowupStatusOptions}>
              <Label text="Session 6 Status" />
            </Item>
            <Item dataField={'session6ExpectedCompletionDate'} disabled="true">
              <Label text="Session 6 Expected Completion Date" />
            </Item>
            <Item dataField={'session6CompletionDate'} disabled="true">
              <Label text="Session 6 Completion Date" />
            </Item>
          </Item>

          <Item dataField={'remark'} editorType="dxTextArea" editorOptions={{ minHeight: 200 }}>
            <Label text="Remarks" />
          </Item>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button width={'50%'} style={{ backgroundColor: '#ff5722', color: '#FFFFFF', marginRight: "10px" }} onClick={onSubmit} text='Save'></Button>
          <Button width={'50%'} style={{ backgroundColor: '#ff5722', color: '#FFFFFF', marginLeft: "10px" }} onClick={popupappear} text='Delete'></Button>
        </div>
      </div>
      <Popup
        visible={popupvisible}
        dragEnabled={true}
        // hideOnOutsideClick={true}
        // content={popupContent}
        width={500}
        height={380}
        title='Delete User'
      >
        <div>
          <h6>Are you sure you want to delete this patient? This action cannot be undone. </h6>
        </div>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={deleteuserButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeButtonOptions}
        />

      </Popup>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
